var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card mb-3",staticStyle:{"width":"100%"}},[(_vm.showPreview)?_c('div',{staticClass:"signature-preview p-4"},[_c('el-button',{staticClass:"p-2",attrs:{"type":"danger","plain":""},on:{"click":function($event){_vm.showPreview = false;
          _vm.activeName = 'letter';}}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(" Edit")]),_c('div',{staticClass:"el-upload"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getCurrentUserSign}})])],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.verificationLoading),expression:"verificationLoading"}],staticClass:"signature-box"},[_c('el-tabs',{staticClass:"mt-2",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"Text Signature","name":"letter"}},[(_vm.getCurrentUserSign)?_c('h4',{staticClass:"font-weight-normal mb-1"},[_vm._v(" Current Signature ")]):_vm._e(),_c('div',{staticClass:"el-upload"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getCurrentUserSign}})]),_c('el-form',[_c('el-form-item',{staticClass:"mb-05",attrs:{"label":"Select Style"}},[_c('el-select',{attrs:{"placeholder":"Select"},on:{"change":_vm.onChangeStyle},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.outlet),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"Signature Text"}},[_c('el-input',{attrs:{"type":"text","label":"Signature","placeholder":"Enter signature"},model:{value:(_vm.signatureText),callback:function ($$v) {_vm.signatureText=$$v},expression:"signatureText"}}),_c('Div',{staticClass:"fontsize",style:({
                  'font-family': _vm.tstyle,
                  color: this.signatureColor,
                  'max-width': '100%',
                })},[_vm._v(" "+_vm._s(this.signatureText)+" ")])],1)],1),_c('div',{staticClass:"my-3"},[_c('button',{staticClass:"color-btn red",attrs:{"type":"button"},on:{"click":function($event){_vm.signatureColor = '#FF0000'}}}),_c('button',{staticClass:"color-btn black",attrs:{"type":"button"},on:{"click":function($event){_vm.signatureColor = '#000000'}}}),_c('button',{staticClass:"color-btn green",attrs:{"type":"button"},on:{"click":function($event){_vm.signatureColor = '#008000'}}})])],1),_c('el-tab-pane',{attrs:{"label":"Draw Signature","name":"drawer"}},[_c('el-row',[_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.signatureLoading),expression:"signatureLoading"}],attrs:{"span":24}},[(_vm.getCurrentUserSign)?_c('h4',{staticClass:"font-weight-normal mb-1"},[_vm._v(" Current Signature ")]):_vm._e(),_c('div',{staticClass:"el-upload"},[_c('img',{attrs:{"src":_vm.getCurrentUserSign,"height":"100px","width":"100%"}})]),_c('h4',{staticClass:"font-weight-normal"},[_vm._v("Update New Signature Here")]),_c('VueSignaturePad',{ref:"signaturePad",staticClass:"sign-image",attrs:{"id":"signature","height":"75px","width":"498px","options":{
                  penColor: _vm.penColor,
                  onBegin: () => {
                    _vm.$refs.signaturePad.resizeCanvas();
                  },
                }}})],1),_c('el-col',{attrs:{"span":24}},[_c('el-button',{staticClass:"float-right",attrs:{"icon":"el-icon-refresh-left","size":"medium","plain":"","round":"","type":"danger","title":"Undo"},on:{"click":_vm.undo}},[_vm._v("Undo")]),_c('button',{staticClass:"color-btn red",attrs:{"type":"button"},on:{"click":function($event){return _vm.setColor($event,'red')}}}),_c('button',{staticClass:"color-btn black",attrs:{"type":"button"},on:{"click":function($event){return _vm.setColor($event,'black')}}}),_c('button',{staticClass:"color-btn green",attrs:{"type":"button"},on:{"click":function($event){return _vm.setColor($event,'green')}}})],1)],1)],1),_c('el-tab-pane',{attrs:{"label":"Upload Signature","name":"upload"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',[(_vm.getCurrentUserSign)?_c('h4',{staticClass:"font-weight-normal mb-1"},[_vm._v(" Current Signature ")]):_vm._e(),_c('div',{staticClass:"el-upload"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getCurrentUserSign}})])]),_c('h4',{staticClass:"font-weight-normal"},[_vm._v("Update New Signature Here")]),_c('vue-anka-cropper',{staticClass:"upload-file",attrs:{"options":{
                  aspectRatio: false,
                  closeOnSave: false,
                  cropArea: 'box',
                  croppedHeight: 1000,
                  croppedWidth: 1000,
                  cropperHeight: false,
                  dropareaMessage:
                    ' Drag & Drop  file here or use the button below.',
                  frameLineDash: [1, 1000],
                  frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                  handleFillColor: 'rgba(255, 255, 255, 0.2)',
                  handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                  handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                  handleSize: 5,
                  handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                  layoutBreakpoint: 40,
                  maxCropperHeight: 1000,
                  maxFileSize: 8000000,
                  overlayFill: 'rgba(0, 0, 0, 0.5)',
                  previewOnDrag: true,
                  previewQuality: 0.65,
                  resultQuality: 0.8,
                  resultMimeType: 'image/jpeg',
                  resultMimeType: 'image/png',
                  resultMimeType: 'image/jpg',
                  resultMimeType: 'image/gif',
                  selectButtonLabel:
                    'Files Supported : JPG, PNG, JPEG, GIF',
                  showPreview: true,
                  skin: 'light',
                  uploadData: {},
                  uploadTo: false,
                },"name":"logo"},on:{"cropper-error":_vm.fileError,"cropper-preview":_vm.storeFileToUpload,"cropper-file-selected":_vm.storeFileToUpload,"cropper-saved":function($event){return _vm.someAction(_vm.cropData)}}})],1)],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }